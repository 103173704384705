.card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.card h3 {
  color: #ffffff;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 0.8px;
  margin: 0;
  padding-bottom: 4px;
}

.card p {
  color: var(--MindSets-Light-Grey, #d9d9d9);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 0.28px;
  margin: 0;
}

.card .title {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-left: 16px;
}

.card img {
  height: 104px;
}
