.contact {
  background-color: #ffffff;
}

.contact .other-image {
  height: 160px;
  background-image: url("/public/other.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

@media only screen and (min-width: 768px) {
  .contact .other-image {
    height: 240px;
  }
}

.contact .page {
  margin: 40px 40px 80px 40px;
  text-align: left;
}

@media only screen and (min-width: 768px) {
  .contact .page {
    max-width: 600px;
    margin: 60px auto;
  }
}

.contact .page a {
  color: var(--MindSets-Dark-Grey, #1E1E1E);
  text-decoration: none;
}

.contact .page a:hover {
  text-decoration: underline;
}

.contact .page h1 {
  color: var(--MindSets-Black, #000);
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 7.28px;
  text-transform: uppercase;
  margin: 0;
  padding-bottom: 32px;
}

.contact .page h3 {
  color: var(--MindSets-Dark-Grey, #1E1E1E);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.44px;
  text-transform: uppercase;
  margin: 0;
  padding-bottom: 4px;
}

.contact .page h2 {
  color: var(--MindSets-Dark-Grey, #1E1E1E);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 0.48px;
  margin: 0;
  padding-bottom: 12px;
}

.contact .page p {
  color: var(--MindSets-Dark-Grey, #1E1E1E);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.contact .form {
  display: flex;
  flex-direction: column;
}

.contact .form label {
  color: var(--MindSets-Dark-Grey, #1E1E1E);
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  padding-top: 24px;
}

.contact .form input,
.contact .form textarea {
  padding: 4px 0px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid var(--MindSets-Dark-Grey, #1E1E1E);
}

.contact .form input:focus,
.contact .form input:focus-visible,
.contact .form textarea:focus,
.contact .form textarea:focus-visible {
  outline: none;
}

.contact .form .button {
  display: flex;
  padding: 12px 8px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 100px;
  border: 1px solid var(--MindSets-Blue, #1481BA);
  background: var(--MindSets-Blue, #1481BA);
  margin-top: 32px;
  cursor: pointer;
}

@media only screen and (min-width: 768px) {
  .contact .form .button {
    width: fit-content;
    align-self: center;
    padding: 12px 24px;
  }
}

.contact .form .button span {
  color: var(--MindSets-Almost-White, #F7F7F7);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 1.92px;
  text-transform: uppercase;
}

.contact .complete p {
  font-weight: bold;
}
