.slick-arrow.slick-next {
  top: auto;
  bottom: -28px;
  right: 0;
  width: 42px;
  height: 42px;
  z-index: 1000;
}

.slick-arrow.slick-next .arrow-wrapper {
  display: flex;
  width: 24px;
  padding: 12px 8px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 100px;
  border: 1px solid var(--MindSets-Dark-Grey, #1E1E1E);
  background: var(--MindSets-Dark-Grey, #1E1E1E);
}

.slick-arrow.slick-next svg {
  width: 16px;
  height: 16px;
}
