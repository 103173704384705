.about {
  background-color: #ffffff;
}

.about .other-image {
  height: 160px;
  background-image: url("/public/other.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

@media only screen and (min-width: 768px) {
  .about .other-image {
    height: 240px;
  }
}

.about .page {
  margin: 40px 40px 80px 40px;
  text-align: left;
}

@media only screen and (min-width: 768px) {
  .about .page {
    max-width: 600px;
    margin: 60px auto;
  }
}

.about .page a {
  color: var(--MindSets-Dark-Grey, #1E1E1E);
  text-decoration: none;
}

.about .page a:hover {
  text-decoration: underline;
}

.about .page h1 {
  color: var(--MindSets-Black, #000);
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 7.28px;
  text-transform: uppercase;
  margin: 0;
  padding-bottom: 32px;
}

.about .page h3 {
  color: var(--MindSets-Dark-Grey, #1E1E1E);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.44px;
  text-transform: uppercase;
  margin: 0;
  padding-bottom: 4px;
}

.about .page h2 {
  color: var(--MindSets-Dark-Grey, #1E1E1E);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 0.48px;
  margin: 0;
  padding-bottom: 12px;
}

.about .page p {
  color: var(--MindSets-Dark-Grey, #1E1E1E);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
