.exercise .info {
  display: flex;
  padding: 20px 20px 50px 20px;
  flex-direction: column;
  gap: 20px;
  margin: 0;
  border-radius: 8px;
  background: var(--MindSets-Dark-Grey, #1E1E1E);
  align-items: center;
  position: relative;
  max-width: 290px;
  /* max-height: 495px; */
}

@media only screen and (min-width: 768px) {
  .exercise .info {
    height: 495px;
  }
}

.exercise .info .box {
  position: relative;
  border: 2px solid #C2C2C2;
}

.exercise .info img {
  width: 100%;
  min-width: 270px;
  object-fit: cover;
}

.exercise .info .box img {
  height: 160px;
  width: 100%;
  min-width: 280px;
  object-fit: cover;
  object-position: top;
}

.exercise .info .box img.empty {
  height: 216px; 
}

.exercise .info .box p {
  color: var(--MindSets-Almost-White, #F7F7F7);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 0.28px;
  text-align: left;
  margin: 0;
  padding: 12px;
}

.exercise .info .buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  position: absolute;
  bottom: -20px;
}

@media only screen and (min-width: 768px) {
  .exercise .info .buttons {
    display: none;
  }
}

.exercise .info .buttons .button {
  display: flex;
  height: 40px;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  border: 1px solid var(--MindSets-Blue, #1481BA);
  background: var(--MindSets-Blue, #1481BA);
  padding: 0;
  margin: 0;
}

.exercise .info .buttons .button span {
  color: var(--MindSets-Almost-White, #F7F7F7);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 1.68px;
  text-transform: uppercase;
  padding: 12px 20px;
  margin: 0;
  display: flex;
  align-items: center;
}

.exercise .bottom {
  display: block;
}

@media only screen and (min-width: 768px) {
  .exercise .bottom {
    display: none;
  }
}
