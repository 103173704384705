.hero {
  height: 100vh;
  background: linear-gradient(180deg, rgba(30, 30, 30, 0) 40.05%, #000000 100%),
    url("/public/hero.png") lightgray -235.743px -146.291px / 248.909% 118.747%
      no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}

@media only screen and (min-width: 768px) {
  .hero {
    height: 600px;
  }
}

.hero .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px 20px;
}

@media only screen and (min-width: 768px) {
  .hero .header {
    padding: 70px 80px;
  }
}

.hero .header .nav a {
  color: var(--MindSets-Almost-White, #F7F7F7);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 4.16px;
  text-transform: uppercase;
  text-decoration: none;
  padding-left: 60px;
}

.hero .header .nav a:hover {
  text-decoration: underline;
}

.hero .header .nav {
  display: none;
}

@media only screen and (min-width: 768px) {
  .hero .header .burger {
    display: none;
  }

  .hero .header .nav {
    display: block;
  }
}

.hero .logo-container {
  display: block;
}

@media only screen and (min-width: 768px) {
  .hero .logo-container {
    display: none;
  }
}

.hero .logo-container img.primary {
  width: 250px;
}

.hero .bottom {
  margin: 0 16px 28px 16px;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

@media only screen and (min-width: 768px) {
  .hero .bottom {
    text-align: left;
    margin: 0 60px 60px;
    display: flex;
    justify-content: space-between;
  }

  .hero .bottom .headline {
    max-width: 440px;
  }
}

.hero .bottom img.primary {
  display: none;
}

@media only screen and (min-width: 768px) {
  .hero .bottom img.primary {
    display: block;
    width: 400px;
  }
}

.hero .bottom .blurb {
  display: block;
}

@media only screen and (min-width: 768px) {
  .hero .bottom .blurb {
    display: none;
  }
}

.hero .center {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media only screen and (min-width: 768px) {
  .hero .center {
    display: none;
  }
}

.hero .center .down-arrow-icon {
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 8px;
  border-radius: 56px;
  background-color: var(--MindSets-Blue, #1481BA);
}

@keyframes bouncy {
  0% { transform: translate(0px, 0px); }
  15% { transform: translate(0px, 10px); }
  30% { transform: translate(0px, 5px); }
  45% { transform: translate(0px, 10px); }
  60% { transform: translate(0px, 0px); }
}

.hero .center .bouncy {
  animation: bouncy 1s linear 2s infinite forwards;
}

.hero h1 {
  color: var(--MindSets-Almost-White, #f7f7f7);
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 7.28px;
  text-transform: uppercase;
  margin: 0;
  padding-bottom: 60px;
}

@media only screen and (min-width: 768px) {
  .hero h1 {
    padding-bottom: 0;
  }
}

.hero h5 {
  color: var(--MindSets-Light-Grey, #d9d9d9);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 4.16px;
  text-transform: uppercase;
  margin: 0;
  padding-top: 44px;
  padding-bottom: 8px;
}

.hero p {
  color: var(--MindSets-Almost-White, #f7f7f7);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin: 24px 0;
}

.cards {
  padding: 0 28px;
}

@media only screen and (min-width: 768px) {
  .cards {
    padding: 80px 60px;
  }
}

.cards h2 {
  color: var(--MindSets-Light-Grey, #d9d9d9);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 3.2px;
  text-transform: uppercase;
  margin: 24px 0;
  text-align: left;
  display: block;
}

.cards .exercise h2 {
  color: var(--MindSets-Almost-White, #F7F7F7);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 0.48px;
  text-align: left;
  margin: 0;
  text-transform: none;
}

.cards .blurb {
  display: none;
}

@media only screen and (min-width: 768px) {
  .cards .blurb {
    display: block;
    max-width: 630px;
    text-align: left;
    padding: 60px 80px 60px 0;
  }
}

.cards .exercise-container {
  display: flex;
  flex-direction: row;
}

@media only screen and (min-width: 768px) {
  .cards .exercise-container {
    align-items: flex-start;
    justify-content: center;
    gap: 16px;
  }
}

.cards .exercise-wrapper {
  display: none;
}

@media only screen and (min-width: 768px) {
  .cards .exercise-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: sticky;
    top: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .cards .exercise-wrapper {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }
}

.cards .exercise {
  display: none;
}

@media only screen and (min-width: 768px) {
  .cards .exercise {
    display: block;
  }
}

.cards .card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 100%;
  gap: 12px;
}

@media only screen and (min-width: 768px) {
  .cards .card-container {
    flex-direction: column;
    flex-wrap: wrap;
    flex-basis: 50%;
  }
}

@media only screen and (min-width: 1400px) {
  .cards .card-container {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.cards .card-container .card-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  background-color: var(--MindSets-Dark-Grey, #1E1E1E);
  width: 100%;
  cursor: pointer;
  border-radius: 8px;
}

@media only screen and (min-width: 768px) {
  .cards .card-container .card-wrapper {
    flex-basis: 48%;
    box-sizing: border-box;
    min-width: 296px;
    transition: all 0.5s;
    background-color: var(--MindSets-Light-Grey, #d9d9d9);
    /* border: 2px solid var(--MindSets-Light-Grey, #d9d9d9); */
  }

  .cards .card-container .card-wrapper .card h3,
  .cards .card-container .card-wrapper .card p {
    color: var(--MindSets-Dark-Grey, #1E1E1E);
  }

  .cards .card-container .card-wrapper:hover,
  .cards .card-container .card-wrapper.selected {
    background-color: var(--MindSets-Dark-Grey, #1E1E1E);
    /* border: 2px solid var(--MindSets-Dark-Grey, #1E1E1E); */
  }

  .cards .card-container .card-wrapper:hover .card h3,
  .cards .card-container .card-wrapper.selected .card h3,
  .cards .card-container .card-wrapper:hover .card p,
  .cards .card-container .card-wrapper.selected .card p {
    color: #ffffff;
  }
}

.donate {
  padding: 80px 40px;
}

@media only screen and (min-width: 768px) {
  .donate {
    max-width: 600px;
    margin: 0 auto;
    padding: 60px 0;
  }
}

.donate h2 {
  color: var(--MindSets-Almost-White, #f7f7f7);
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 6.24px;
  text-transform: uppercase;
}

.donate p {
  color: var(--MindSets-Almost-White, #f7f7f7);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  letter-spacing: 0.64px;
}

.donate .button {
  display: flex;
  padding: 12px 8px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 100px;
  border: 1px solid var(--MindSets-Blue, #1481BA);
  background: var(--MindSets-Blue, #1481BA);
}

@media only screen and (min-width: 768px) {
  .donate .button {
    max-width: 300px;
    margin: 0 auto;
  }
}

.donate .button span {
  display: flex;
  align-items: center;
  color: var(--MindSets-Almost-White, #f7f7f7);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 1.92px;
  text-transform: uppercase;
}

.donate a {
  text-decoration: none;
}

.donate .alternative {
  margin-bottom: 20px;
}

.donate .alternative .button {
  display: flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 100px;
  border: 1px solid var(--MindSets-Black, #000);
  background: var(--MindSets-Almost-White, #F7F7F7);
}

.donate .alternative .button span {
  display: flex;
  align-items: center;
  color: var(--MindSets-Black, #000);
  text-align: center;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 1.44px;
  text-transform: uppercase;
}

.donate .alternative img {
  margin-top: 12px;
}

.drawer {
  background-color: #000000;
  height: 100vh;
}

.drawer .top {
  padding: 24px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.drawer .top span {
  color: var(--MindSets-Almost-White, #F7F7F7);
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  letter-spacing: 0.28px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.drawer .content {
  margin: 0 20px;
}

.drawer .content .bottom {
  color: var(--MindSets-Light-Grey, #D9D9D9);
  font-size: 28px;
  font-style: normal;
  font-weight: 200;
  line-height: 100%;
  text-transform: uppercase;
  position: relative;
  /* position: absolute;
  bottom: 0;
  left: 0;
  right: 0; */
  margin-top: 60px;
  /* background: linear-gradient(transparent, #000000, #000000); */
}

.drawer .content .bottom span {
  padding: 0 8px;
}

.slick-slider {
  height: 100vh;
}

.slick-prev:before, .slick-next:before {
  display: none;
}

.slick-slide img {
  width: 100%;
}

.slick-list {
  overflow-y: scroll;
}
