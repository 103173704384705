.bm-menu-wrap {
  background-color: #ffffff;
}

.bm-burger-button {
  position: absolute;
  right: 20px;
  top: 24px;
  width: 24px;
  height: 18px;
}

.bm-item.top {
  margin-top: 40px;
}

.bm-item.middle {
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  text-align: left;
  padding: 0 40px;
}

.menu-item {
  display: block;
  color: var(--MindSets-Black, #000);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 6.24px;
  text-transform: uppercase;
  text-decoration: none;
  margin-bottom: 20px;
}
