.footer {
  display: flex;
  padding: 40px 20px 100px 20px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  background: var(--MindSets-Black, #000);
}

@media only screen and (min-width: 768px) {
  .footer {
    padding: 60px 80px;
  }
}

.footer-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

@media only screen and (min-width: 768px) {
  .footer-container {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }
}

.footer img.small {
  width: 130px;
  order: 1;
}

.footer .links {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  align-self: stretch;
  order: 2;
}

.footer .links a {
  color: var(--MindSets-Almost-White, #F7F7F7);
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  letter-spacing: 0.56px;
  text-decoration: none;
}

.footer .links a:hover {
  text-decoration: underline;
}

.footer .support {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  order: 3;
}

@media only screen and (min-width: 768px) {
  .footer .support {
    order: 4;
  }
}

.footer .support h5 {
  color: var(--MindSets-Almost-White, #F7F7F7);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.44px;
  text-transform: uppercase;
  margin: 0 0 4px 0;
}

.footer .support p {
  color: var(--MindSets-Almost-White, #F7F7F7);
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  letter-spacing: 0.56px;
  margin: 0;
}

.footer .support a {
  text-decoration: none;
}

.footer .button {
  display: flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 100px;
  border: 1px solid var(--MindSets-Black, #000);
  background: var(--MindSets-Almost-White, #F7F7F7);
}

.footer .button span {
  display: flex;
  align-items: center;
  color: var(--MindSets-Black, #000);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 1.44px;
  text-transform: uppercase;
}

.footer .join {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  order: 4;
}

@media only screen and (min-width: 768px) {
  .footer .join {
    flex-direction: column-reverse;
    order: 3;
    gap: 8px;
  }
}

.footer .join .right {
  text-align: right;
}

@media only screen and (min-width: 768px) {
  .footer .join .right {
    text-align: center;
  }
}

.footer .join .socials svg {
  padding-left: 0;
  padding-right: 16px;
}

@media only screen and (min-width: 768px) {
  .footer .join .socials svg {
    padding: 0 8px;
  }
}

.footer .join h5 {
  color: var(--MindSets-Almost-White, #F7F7F7);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.44px;
  text-transform: uppercase;
  margin: 0 0 4px 0;
}

.footer .join p {
  color: var(--MindSets-Almost-White, #F7F7F7);
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  letter-spacing: 0.56px;
  margin: 0;
}

.footer .copyright {
  color: var(--MindSets-Almost-White, #F7F7F7);
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  letter-spacing: 0.4px;
}

@media only screen and (min-width: 768px) {
  .footer .copyright {
    align-self: flex-start;
  }
}
