.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px 20px;
}

@media only screen and (min-width: 768px) {
  .header {
    padding: 38px 80px;
  }
}

.header img.medium {
  height: 80px;
  display: none;
}

@media only screen and (min-width: 768px) {
  .header img.medium {
    display: block;
  }
}

.header .mo {
  display: block;
}

@media only screen and (min-width: 768px) {
  .header .mo {
    display: none;
  }
}

.header .nav a {
  color: var(--MindSets-Black, #000);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 4.16px;
  text-transform: uppercase;
  text-decoration: none;
  padding-left: 60px;
}

.header .nav a:hover {
  text-decoration: underline;
}

.header .nav {
  display: none;
}

@media only screen and (min-width: 768px) {
  .header .burger {
    display: none;
  }

  .header .nav {
    display: block;
  }
}
