.exercise {
  height: calc(100vh - 85px);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (min-width: 768px) {
  .exercise {
    height: auto;
  }
}

.exercise h2 {
  color: var(--MindSets-Almost-White, #F7F7F7);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 0.48px;
  text-align: left;
  margin: 0;
}
